@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansKR";
  src: url("../assets/fonts/NotoSansKR-Thin.woff2") format("woff2");
  font-weight: 100;
  font-display: swap;
}

* {
  font-family: "NotoSansKR";
  box-sizing: border-box;
}
input,
select,
textarea {
  outline: none;
}
video {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.customer {
  background: linear-gradient(0, #f3f3f3 35%, white 35%);
}

/* apexcharts */
.apx-legend-position-right {
  display: none !important;
}

/* scroll */
.scroll {
  -ms-overflow-style: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}

/* react-slick */
.slick-slide {
  margin: 15px 0;
  display: flex !important;
  justify-content: center;
  padding: 0 10px;
}
.slick-dots li {
  margin: 0 1px !important;
}

/* kakaomap */
.selected_btn {
  color: white;
  background: linear-gradient(#425470, #5b6b8a);
}

.btn {
  background-color: white;
}

.custom_button {
  position: absolute;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.custom_button label {
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 3px 3px 5px #00000057;
}

.custom_typecontrol span {
  display: block;
  width: 65px;
  height: 30px;
  float: left;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

img {
  max-width: 100%;
}

input[id="btnDistrict"]:checked + label {
  color: white;
  background: linear-gradient(#425470, #5b6b8a);
}

/* input[id="btnLocation"]:checked + label {
  color: white;
  background: linear-gradient(#425470, #5b6b8a);
} */

/* map filter */
.rc-slider-tooltip-inner {
  background-color: white !important;
  box-shadow: none !important;
  padding: 1px 2px !important;
  font-weight: bold !important;
}

.maemae .rc-slider-track {
  background-color: #2699fb;
}

.maemae .rc-slider-tooltip-inner {
  color: #2699fb;
}

.imcha .rc-slider-track {
  background-color: #ea4075;
}

.imcha .rc-slider-tooltip-inner {
  color: #ea4075;
}

/* faq */
.faq-row {
  display: flex !important;
  justify-content: center !important;
  border: 1px solid #70707080;
  margin-top: 5px;
  padding-left: 50px !important;
  min-height: 77px;
}

.icon-wrapper {
  top: auto !important;
  max-width: 40px !important;
  max-height: 40px !important;
}
